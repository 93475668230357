@import "../../../styles/base";

.floatingCart {
  position: sticky;
  background-color: $white;
  z-index: 3;

  @include untilDesktop {
    margin: 0 -10px;
    top: 73px;
    @include boxShadow;
    background-color: $grey-light;
  }
  @include fromDesktop {
    width: 240px;
    float: right;
    top: 120px;
    margin-top: 32px;
    margin-bottom: 30px;
  }

  &.floatingCartBottom {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    position: fixed;
    bottom: 0;
    top: auto;
    @include fromDesktop {
      display: none;
    }
  }
}

.cart {
  @include fromDesktop {
    @include boxShadow;

    border-radius: $border-radius;
    width: 240px;
    float: right;
    margin-bottom: 30px;
    max-height: calc(100vh - 32px - 88px - 75px);
    overflow-y: auto;
  }
}

.header {
  display: flex;
  position: relative;
  z-index: 2;
  font-weight: 900;
  background-color: $grey-light;
  padding: 1rem 1.5rem 0 1.5rem;
  font-weight: 400;
  border-radius: 0;
  justify-content: center;
  @include fromDesktop {
    justify-content: space-between;
  }

  a {
    color: $white;
  }

  .floatingCartBottom & {
    display: none;
  }

  @include fromDesktop {
    border-radius: $border-radius $border-radius 0 0;
    padding: 14px 15px;
    font-weight: 400;
    @include gradient;

    color: $white;

    a {
      color: $white;
    }
  }
}

.subtotal {
  font-size: $font-m;
  b {
    font-weight: $font-bold;
  }

  @include fromDesktop {
    font-size: $font-m;
    b {
      font-weight: 700;
    }
  }
}

.cartNum {
  font-size: $font-xs;
  float: right;
  flex: 0 0 40px;
  min-height: 26px;
  text-align: center;
  background-image: url(../../../images/bg/basket-black.png);
  background-repeat: no-repeat;
  background-position: 2px bottom;
  background-size: 30px auto;
  margin-left: 10px;
  position: relative;

  @include fromDesktop {
    background-image: url(../../../images/bg/basket.svg);
    background-size: 29px auto;
  }
  a {
    display: block;
    color: $white;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    text-decoration: none;
    margin: 0;
    position: absolute;
    z-index: 100;
    left: 12px;
    top: 2px;
    background-color: $green;

    @include fromDesktop {
      background: $blue-dark;
    }
  }
  button {
    display: block;
    background: $green;
    color: $white;
    height: 18px;
    width: 18px;
    border-radius: 50%;
    margin: auto;
    text-decoration: none;
    border: 0;
    outline: none;

    @include fromDesktop {
      background: $blue-dark;
    }
  }
}

.rowContainer {
  @include untilDesktop {
    position: relative;
    display: flex;
    background: $grey-light;
    border-top: 1px solid $grey;
    border-bottom: 1px solid $grey;
    max-width: 100vw;
    overflow: auto;

    .floatingCartTop & {
      border: none;
      gap: 0.5rem;
      background-color: $grey-light;
      justify-content: center;
      padding: 0 1rem;
    }

    .floatingCartBottom & {
      display: none;
    }
  }
}

.row {
  text-align: center;
  font-size: $font-sm;
  padding: 1rem 0 1rem 0;
  flex-grow: 1;
  @include fromMobile {
    font-size: $font-sm-default;
  }

  @include fromDesktop {
    text-align: left;
    position: relative;
    padding: 1rem;
    font-size: $font-default;
  }

  &:empty {
    display: none;
  }
}

.currentSection {
  border-bottom: 3px solid $green-dark;

  @include fromDesktop {
    border-left: 2px solid $green-dark;
    background: $green-light;
    border-bottom: none;
  }
}

.rowTitle {
  @include fromDesktop {
    font-weight: normal;
  }
}

.tick {
  display: none;
}

.removeLink {
  color: $blue;
  cursor: pointer;
}

.rooms {
  display: block;
  width: 100%;

  b {
    display: block;
    margin-top: 5px;
  }
}

.roomsTitle {
  display: flex;
  gap: 20px;
  justify-content: space-between;
}

a.link {
  text-decoration: none;
  text-transform: uppercase;
  display: block;
  font-weight: normal;
  position: relative;

  @include fromDesktop {
    color: $black;
  }

  strong {
    padding-right: 15px;
    padding-left: 0;
    position: relative;
    display: inline-block;
    @include fromDesktop {
      padding-right: 20px;
      padding-left: 0;
      display: block;
    }

    &.complete {
      strong {
        &::after {
          content: "";
          display: inline-block;
          border: 2px solid $green;
          border-width: 0 3px 3px 0;
          position: absolute;
          transform: rotate(45deg);
          height: 6px;
          width: 3px;
          top: 4px;
          right: 2px;
          @include fromTablet {
            height: 7px;
            width: 4px;
            top: 5px;
            right: 8px;
          }
        }
      }

      @include fromDesktop {
        strong {
          &::before {
            content: "";
            display: inline-block;
            height: 20px;
            width: 20px;
            border: 1px solid $green;
            border-radius: 50%;
            position: absolute;
            top: 0;
            right: 0;
          }
        }
      }
    }
  }

  .currentSection & {
    color: $black;
  }
}

.row + .row {
  @include fromDesktop {
    border-top: solid $grey 1px;
  }
}

.rowItem {
  @include untilDesktop {
    display: none;
  }

  margin-top: 5px;
  font-size: $font-s;
  display: flex;
  gap: 20px;
}

.rowItemHide {
  display: none;
  @include fromDesktop {
    margin-top: 5px;
    font-size: $font-s;
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }
}

@keyframes ticketInOut {
  0% {
    opacity: 0;
    transform: translateY(0);
  }

  20% {
    opacity: 1;
  }

  60% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 1;
    transform: translateY(-100%);
  }
}

.qty,
.ticketDate {
  color: gray;
}

.loading {
  opacity: 1;
}

.loading:first-child {
  opacity: 1;
}

.disabled {
  cursor: not-allowed;
  @include fromDesktop {
    font-weight: normal;
  }
}

.cartNav {
  display: none;

  @include fromDesktop {
    display: flex;
    justify-content: space-around;
    align-items: center;
    clear: both;
  }

  &:global(.hotels) {
    display: none;

    .floatingCartBottom & {
      display: none;
    }
  }

  .floatingCartBottom & {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 1rem;

    @include fromDesktop {
      padding: 0;
      background-color: transparent;
      border: none;
    }
  }
}

.floatingCartTop {
  &.loading {
    .rowContainer,
    .header {
      background-color: $green-light;
    }
  }
}

.floatingCartBottom {
  &.loading {
    .cartNav {
      background-color: $green-light;
    }
    .cartNav .btn {
      animation: animationAttention 1s ease 0s 1 normal forwards;
    }
  }
}

@keyframes animationAttention {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.05);
  }

  100% {
    transform: scale(1);
  }
}

a.back {
  text-decoration: none;
  text-transform: uppercase;
  font-size: $font-m;
  position: relative;
  padding-left: 10px;
  // display: none;

  &::before {
    content: "";
    display: block;
    height: 8px;
    width: 8px;
    border: 1px solid $blue;
    border-width: 0 0 2px 2px;
    transform: rotate(45deg);
    position: absolute;
    left: 0;
    top: 8px;
  }

  @include fromMobile {
    display: block;
  }

  .floatingCartBottom & {
    @include btnAltBack;
  }
}

.btn {
  @include btn;
  margin-left: auto;
}

.nextText {
  display: none;
}

// CRO-1235 Desktop only
:global(.CRO-1235-Display-full-desktop-basket) {
  @include fromTablet {
    // spinner
    .floatingCartTop {
        &.loading {
          .rowContainer {
            @include loading();
          }
        }
      }

    .cart {
      max-height: calc(100vh - 32px - 88px - 120px);
    }

    .header {
      position: sticky;
      top: 0;
    }

    // adjusting the size to match a-link
    .cartNum button {
      width: 16px;
      height: 16px;
      margin-top: 2px;
      padding: 0px 2px;
      background: $green;
    }

    .cartNum a {
      background: $green;
    }

    .currentSection {
      border-left: 4px solid $green-dark;
      padding-left: 0.8rem;
    }

    .cro1235Loading {
      @include loading();

      &::after {
        left: 95% !important;
      }
    }

    .hasHotels {
      &:global(.hotels) {
        display: flex;
      }
    }
  }
}
