@import "../../../styles/base";

.YearPicker {
  font-size: $font-m;
  text-align: right;
  border-bottom: solid $grey 1px;
  margin-bottom: 14px;
  padding-bottom: 12px;

  .yearOptions {
    display: inline-block;
    width: fit-content;
    margin-top: 8px;
  }

  input {
    display: none;
  }

  label {
    cursor: pointer;
    padding-left: 26px;
    margin-left: 10px;
    position: relative;

    @include fromTablet {
      margin-left: 10px;
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      height: 21px;
      width: 21px;
      border: 1px solid $grey;
      border-radius: 50%;
      left: 0;
      top: 0;
      box-shadow: inset 0 2px 3px 0 rgba(0,0,0,0.3);
    }
  }

  input:checked + label {
    &::after {
      content: '';
      display: block;
      position: absolute;
      height: 12px;
      width: 12px;
      background: $blue;
      border-radius: 50%;
      left: 5.5px;
      top: 6px;
    }
  }
}
